import { createRouter, createWebHistory } from 'vue-router';
import MainTrackSearch from '../components/main/MainTrackSearch.vue';
import NotFound from '../components/main/NotFound.vue';

const routes = [
  {
      path: '/',
      redirect: '/music-match',
  },
  {
    path: '/music-match/',
    name: 'Home',
    meta: {
        title: "Music Match",
    },
    component: MainTrackSearch
  },
  {
     path: "/notFound",
     name: "notFound",
     meta: {
        title: "Error Page",
     },
     component: NotFound
   },
   {
     path: "/:pathMatch(.*)*",
     redirect: "/notFound",
     meta: {
       title: "Error Page",
     },
   }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.afterEach((to) => {
    const title = to.meta.title;
    if(title) document.title = title;
});

export default router;